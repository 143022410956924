application.register("lib-litepicker", class extends Stimulus.Controller {
    static get values() {
        return {
            options: Object,
            selectedDate: Array
        }
    }

    connect() {

        if (this.element.querySelector(".litepicker") !== null) return false;

        importScript([cdnjs.litepicker, cdnjs.dayjs, cdnjs.dayjsBetween], () => {
            dayjs.extend(dayjs_plugin_isBetween);

            let bookedDays = [];
            let disabledDays = [];

            if (typeof this.getValue("options")["bookedDays"] !== "undefined") {
                bookedDays = this.getValue("options")["bookedDays"];
            }

            if (typeof this.getValue("options")["disabledDays"] !== "undefined") {
                disabledDays = this.getValue("options")["disabledDays"];
            }
            
            new Litepicker({
                element: this.element,
                lang: lang,
                numberOfMonths: document.documentElement.clientWidth < 767 ? 1 : 2,
                numberOfColumns: document.documentElement.clientWidth < 767 ? 1 : 2,
                minDate: new Date(),
                selectForward: true,
                inlineMode: true,
                singleMode: false,
                showTooltip: false,
                buttonText: {
                    previousMonth: '<span class="icon icon--arrow-left"></span>',
                    nextMonth: '<span class="icon icon--arrow-right"></span>'
                },
                lockDays: bookedDays.concat(disabledDays),
                disallowLockDaysInRange: true,
                setup: (picker) => {
                    picker.on("render:day", (element) => {
                        element.innerHTML = `<span>${element.innerHTML}</span>`;

                        if (this.getLockDates(parseInt(element.dataset.time), bookedDays).includes("range")) {
                            element.classList.add("is-booked");
                            element.setAttribute("aria-label",  this.getValue("options")["lang"]["booked"]);
                        }

                        if (this.getLockDates(parseInt(element.dataset.time), bookedDays).includes("range-start")) {
                            element.classList.add("is-booked-start");
                        }

                        if (this.getLockDates(parseInt(element.dataset.time), bookedDays).includes("range-end")) {
                            element.classList.add("is-booked-end");
                        }

                        if (this.getLockDates(parseInt(element.dataset.time), bookedDays).includes("single")) {
                            element.classList.add("is-booked", "is-booked-start", "is-booked-end");
                            element.setAttribute("aria-label", this.getValue("options")["lang"]["booked"]);
                        }

                        if (this.getLockDates(parseInt(element.dataset.time), disabledDays).length > 0) {
                            element.classList.add("is-disabled");
                            element.setAttribute("aria-label",  this.getValue("options")["lang"]["unavailable"]);
                        }
                    });

                    picker.on("selected", (date1, date2) => {
                        this.setValue("selectedDate", [date1.dateInstance, date2.dateInstance]);
                        this.element.dispatchEvent(new Event('change', { 'bubbles': true }));
                    });
                }
            });

            if (!document.documentElement.classList.contains("ie") && (typeof this.getValue("options")["bookedDays"] !== "undefined" || typeof this.getValue("options")["disabledDays"] !== "undefined")) {
                let html = `
                    <div class="part_ui_dropdown">
                        <div class="elm_tooltip_text">
                            *text*
                        </div>
                    </div>
                `;
                this.element.addEventListener("mousemove", (e) => {
                    if (document.documentElement.classList.contains("no-touch") && (e.target.classList.contains("is-booked") || e.target.classList.contains("is-disabled"))) {
                        let tippyParent = this.element.closest("[data-tippy-root]");
                        let tippyX = 0;
                        let tippyY = 0;

                        if(tippyParent !== null) {
                            let transform = window.getComputedStyle(tippyParent).transform.match(/\d+/g);
                            tippyX = transform[4];
                            tippyY = transform[5];
                        }

                        if (this.element.querySelector(".part_ui_dropdown") === null) {
                            this.element.insertAdjacentHTML("beforeend", html.replace("*text*", e.target.getAttribute("aria-label")));
                        }
                        this.element.querySelector(".part_ui_dropdown").style.left = (e.clientX) - tippyX + 'px';
                        this.element.querySelector(".part_ui_dropdown").style.top = e.clientY - tippyY - 16 + "px";
                        this.element.querySelector(".part_ui_dropdown").style.display = "flex";
                    }
                }, true);

                this.element.addEventListener("mouseleave", (e) => {
                    if (document.documentElement.classList.contains("no-touch") && this.element.querySelector(".part_ui_dropdown") !== null) {
                        this.element.querySelector(".part_ui_dropdown").remove();
                    }
                }, true);
            }
        });
    }

    getLockDates(date, dates) {
        let values = [];

        dates.map((dates) => {
            if (Array.isArray(dates)) {
                if (dayjs(date).isBetween(dates[0], dates[1], null, '[]')) {
                    values.push("range");
                }

                if (dayjs(date).format("YYYY-MM-DD").toString() === dates[0]) {
                    values.push("range-start");
                }

                if (dayjs(date).format("YYYY-MM-DD").toString() === dates[1]) {
                    values.push("range-end");
                }
            } else {
                if (dayjs(date).format("YYYY-MM-DD").toString() === dates) {
                    values.push("single");
                }
            }
        });

        return values;
    }
});