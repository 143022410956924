window.lui_map_responses = {};

application.register("lib-map", class extends Stimulus.Controller {
    connect() {
        const self = this;
        const options = JSON.parse(this.data.get("options"));
        const markersUrl = this.data.get("markers");

        inView(self.element, () => {
            importScript(cdnjs.googlemaps.replace("{apikey}", options["apikey"]), () => {
                let bounds;
                let markers_temp = [];
                let info_template = self.getTarget("infoWindow").innerHTML;

                let map = new google.maps.Map(self.getTarget("map"), {
                    center: new google.maps.LatLng(parseFloat("36.97398854963965"), parseFloat("21.68833684846537")),
                    zoom: 15,
                    zoomControl: true,
                    mapTypeControl: false,
                    scaleControl: false,
                    streetViewControl: false,
                    rotateControl: false,
                    fullscreenControl: false,
                    styles: [
                        {
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#f5f5f5"
                                }
                            ]
                        },
                        {
                            "elementType": "labels.icon",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#616161"
                                }
                            ]
                        },
                        {
                            "elementType": "labels.text.stroke",
                            "stylers": [
                                {
                                    "color": "#f5f5f5"
                                }
                            ]
                        },
                        {
                            "featureType": "administrative.land_parcel",
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#bdbdbd"
                                }
                            ]
                        },
                        {
                            "featureType": "landscape",
                            "elementType": "geometry.fill",
                            "stylers": [
                                {
                                    "color": "#c1c1b2"
                                }
                            ]
                        },
                        {
                            "featureType": "landscape.natural.landcover",
                            "elementType": "geometry.fill",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "featureType": "landscape.natural.landcover",
                            "elementType": "geometry.stroke",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "featureType": "landscape.natural.terrain",
                            "elementType": "geometry.fill",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "featureType": "landscape.natural.terrain",
                            "elementType": "geometry.stroke",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "featureType": "poi",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#eeeeee"
                                }
                            ]
                        },
                        {
                            "featureType": "poi",
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#757575"
                                }
                            ]
                        },
                        {
                            "featureType": "poi.park",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#e5e5e5"
                                }
                            ]
                        },
                        {
                            "featureType": "poi.park",
                            "elementType": "geometry.fill",
                            "stylers": [
                                {
                                    "color": "#979582"
                                }
                            ]
                        },
                        {
                            "featureType": "poi.park",
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#9e9e9e"
                                }
                            ]
                        },
                        {
                            "featureType": "road",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#ffffff"
                                }
                            ]
                        },
                        {
                            "featureType": "road",
                            "elementType": "geometry.fill",
                            "stylers": [
                                {
                                    "color": "#796e65"
                                }
                            ]
                        },
                        {
                            "featureType": "road",
                            "elementType": "geometry.stroke",
                            "stylers": [
                                {
                                    "color": "#796e65"
                                }
                            ]
                        },
                        {
                            "featureType": "road.arterial",
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#757575"
                                }
                            ]
                        },
                        {
                            "featureType": "road.highway",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#dadada"
                                }
                            ]
                        },
                        {
                            "featureType": "road.highway",
                            "elementType": "geometry.fill",
                            "stylers": [
                                {
                                    "color": "#796e65"
                                }
                            ]
                        },
                        {
                            "featureType": "road.highway",
                            "elementType": "geometry.stroke",
                            "stylers": [
                                {
                                    "color": "#796e65"
                                }
                            ]
                        },
                        {
                            "featureType": "road.highway",
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#616161"
                                }
                            ]
                        },
                        {
                            "featureType": "road.local",
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#9e9e9e"
                                }
                            ]
                        },
                        {
                            "featureType": "transit.line",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#e5e5e5"
                                }
                            ]
                        },
                        {
                            "featureType": "transit.station",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#eeeeee"
                                }
                            ]
                        },
                        {
                            "featureType": "water",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#c9c9c9"
                                }
                            ]
                        },
                        {
                            "featureType": "water",
                            "elementType": "geometry.fill",
                            "stylers": [
                                {
                                    "color": "#e4f1fe"
                                }
                            ]
                        },
                        {
                            "featureType": "water",
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#9e9e9e"
                                }
                            ]
                        }
                    ]
                });

                let infoWindow = new google.maps.InfoWindow({
                    content: ""
                });

                bounds = new google.maps.LatLngBounds();

                let iconWidth = (window.innerWidth >= 1366) ? 28 / 1300 * window.innerWidth : 28;
                let iconHeight = (window.innerWidth >= 1366) ? 37 / 1300 * window.innerWidth : 37;
                let iconActiveWidth = (window.innerWidth >= 1366) ? 60 / 1300 * window.innerWidth : 60;
                let iconActiveHeight = (window.innerWidth >= 1366) ? 82 / 1300 * window.innerWidth : 82;

                function generateMarker(item) {
                    // console.log(item);
                    let title = item["title"];
                    let point = new google.maps.LatLng(parseFloat(item["latitude"]), parseFloat(item["longitude"]));
                    let content;
                    let icon = {
                        url: "/assets/img/marker.svg",
                        scaledSize: new google.maps.Size(iconWidth, iconHeight),
                        anchor: new google.maps.Point(iconWidth / 2, iconHeight)
                    };
                    let iconActive = {
                        url: "/assets/img/marker-active.svg",
                        scaledSize: new google.maps.Size(iconActiveWidth, iconActiveHeight),
                        anchor: new google.maps.Point(iconActiveWidth / 2, iconActiveHeight)
                    };

                    let marker = new google.maps.Marker({
                        title: title,
                        map: map,
                        position: point,
                        icon: icon,
                        zIndex: 0,
                    });
                    bounds.extend(point);
                    markers_temp.push(marker);

                    content = info_template;

                    Object.entries(item).forEach((key) => {
                        content = content.replace(`*${key[0]}*`, key[1]);
                    });

                    function showInfo(marker) {
                        for (let marker_temp of markers_temp) {
                            marker_temp.setIcon(icon);
                            marker_temp.setZIndex(0);
                        }
                        marker.setIcon(iconActive);
                        marker.setZIndex(100);

                        infoWindow.close();
                        infoWindow.setContent(content);
                        infoWindow.open(map, marker);
                    }

                    if (item["active"]) {
                        showInfo(marker);
                        map.panTo(marker.position);
                    }

                    marker.addListener('click', () => {
                        showInfo(marker);
                    });

                    marker.addListener('mouseover', () => {
                        showInfo(marker);
                    });

                    map.addListener("click", () => {
                        infoWindow.close();
                        for (let marker_temp of markers_temp) {
                            marker_temp.setIcon(icon);
                            marker_temp.setZIndex(0);
                        }
                    })
                }

                if (typeof markersUrl !== "undefined") {
                    self.getMarkers(markersUrl, (payload) => {
                        for (let item of payload) {
                            generateMarker(item);
                        }

                        if (payload.length > 0) {
                             map.fitBounds(bounds);
                        }
                    });
                }
            });
        })
    }

    getMarkers(url, callback) {
        if (typeof window.lui_map_responses[url] === "undefined") {
            fetch(url, {headers: {'X-Requested-With': 'XMLHttpRequest'}}).then((response) => {
                return response.json();
            }).then((payload) => {
                window.lui_map_responses[url] = payload;
                callback(payload);
            });
        } else {
            callback(window.lui_map_responses[url]);
        }
    }
});
