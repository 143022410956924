application.register("lib-fetch", class extends Stimulus.Controller {
    link(e) {
        e.preventDefault();

        let url = e.currentTarget.getAttribute('data-href');

        if (!url || !url.length) {
            url = e.currentTarget.getAttribute('href');
        }

        fetch(url, {method: 'POST', headers: {'X-Requested-With': 'XMLHttpRequest'}}).then((response) => {
            return response.json();
        }).then((payload) => {
            fetchHandler(payload);
        });
    }

    form(e) {
        e.preventDefault();

        let frm = e.currentTarget,
            formData = new FormData(frm),
            submitButton = frm.querySelector('button[type=submit]');

        if (typeof HTMLFormElement.prototype.checkValidity !== "undefined") {
            if (frm.checkValidity() === false && frm.getAttribute("novalidate") !== null) {
                return false;
            }
        }

        if (submitButton) {
            submitButton.classList.add('state--loading');
            submitButton.disabled = true;
        }

        fetch(frm.getAttribute('action'), {method: frm.getAttribute('method'), body: formData, headers: {'X-Requested-With': 'XMLHttpRequest'}}).then((response) => {
            return response.json();
        }).then((payload) => {
            fetchHandler(payload);
        }).then(() => {
            if (submitButton) {
                frm.querySelector('button[type=submit]').classList.remove('state--loading');
                frm.querySelector('button[type=submit]').disabled = false;
            }
        });
    }

    submitForm(e) {
        e.currentTarget.form.dispatchEvent(new Event('submit', {'bubbles': true, 'cancelable': true}));
    }
});