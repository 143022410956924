application.register("comp_blocks_list", class extends Stimulus.Controller {
    get target() {
        return {
            nav: this.targets.find("nav"),
            body: this.targets.find("body"),
        }
    }
    connect() {
        cssLoaded(()=>{
            if(document.documentElement.classList.contains("ie")) {
                importScript(cdnjs.stickyfill,()=>{
                    Stickyfill.add(this.target.nav);
                });
            }

            if(typeof locomotive !== "undefined" && !locomotive.isMobile) {
                let sections = this.target.body.querySelectorAll("[data-offset]");
                let offsets = {};
                let layout_offset = this.element.offsetTop;

                Array.from(sections).forEach((section)=>{
                    let offset = (typeof section.dataset.offset !== "undefined") ? parseInt(section.dataset.offset) : 0;
                    offsets[section.getAttribute("id")] = section.offsetTop + layout_offset - offset*3;
                });

                locomotive.on("scroll",(e)=>{
                    let position = e.delta.y;

                    for(let i in offsets) {
                        if(offsets[i] <= position) {
                            let active = this.target.nav.querySelector(".state--active")
                            if(active !== null) {
                                active.classList.remove("state--active");
                            }
                            this.target.nav.querySelector(`[href="#${i}"]`).classList.add("state--active");
                        }
                    }
                });
            }
        })
    }
});