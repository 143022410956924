application.register("part_form_booking", class extends Stimulus.Controller {
    get target() {
        return {
            date: this.targets.find("date"),
            arrival: this.targets.find("arrival"),
            departure: this.targets.find("departure"),
        }
    }

    datesChange(dates) {
        importScript([cdnjs.dayjs], ()=>{
            this.target.date.value = `${dayjs(dates[0]).format('D/M')} - ${dayjs(dates[1]).format('D/M/YYYY')}`;
            this.target.date.closest(".part_ui_input").classList.add("state--active");

            this.target.arrival.value = dayjs(dates[0]).format('YYYY-MM-DD');
            this.target.departure.value = dayjs(dates[1]).format('YYYY-MM-DD');
        });

        tippy.hideAll();
    }
});